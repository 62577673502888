<template>
  <div class="container">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb pl-0 mb-0 pb-2">
            <li class="breadcrumb-item"><a href="/">{{ $t('breadcrumb.main-page') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.authorization') }}</li>
        </ol>
    </nav>
    <div class="title-page-mob">
        {{ $t('breadcrumb.authorization') }}
    </div>
    <div class="row signin-wrapper">
      <div class="col-lg-6 col-md-12 signin-col-logo">

            <div class="col-12 px-0">
              <p class="text-primary">{{ $t('instruction.auth') }}:</p>
              <div  style="justify-content: space-between" class="d-flex mob-column-signup">
                <a class="download-instruction"  :href="$t('instruction.auth_file')" :download="$t('instruction.auth')">{{$t('signin-form.download')}}</a>
                <a  @click="$bvModal.show('youtube-modal-signin')" class="download-instruction"  >{{$t('signin-form.watch')}}</a>
              </div>
            </div>
        <div class="col-12 px-0">
          <p class="text-primary">{{ $t('instruction.password') }}:</p>
          <div  style="justify-content: space-between" class="d-flex mob-column-signup">
            <a class="download-instruction"  :href="$t('instruction.password_file')" :download="$t('instruction.password')">{{$t('signin-form.download')}}</a>
            <a  @click="$bvModal.show('youtube-modal-password-recovery')" class="download-instruction"  >{{$t('signin-form.watch')}}</a>
          </div>
        </div>
        <b-modal id="youtube-modal-signin" hide-footer >
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div >
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe width="560" height="315" :src="$t('instruction.auth_video')" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal id="youtube-modal-password-recovery" hide-footer >
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div >
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe width="560" height="315" :src="$t('instruction.password_video')" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <div class="col-lg-6 col-md-12 signin-col-form">
        <div>
          <login-form></login-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm'
export default {
  name: 'Login',
  components: {
    LoginForm
  },
}
</script>
<style scoped>
.signin-wrapper {
  display:flex;
  justify-content: space-between;
  padding-bottom: 85px;
  padding-top: 35px;
}
</style>
<style >
.download-instruction{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor:pointer;
  text-decoration: none;
  max-width:184px;
  display:block;
  padding: 1px 16px;
  position: static;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  text-align: center;
  color: #0070D2;
  flex: none;
  order: 1;
  flex-grow: 0;
  background: #FFFFFF;
  border: 1px solid #DDDBDA;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 30px;
}
.download-instruction:hover {
  text-decoration: none;
}

.signin-col-logo {
  max-width:400px;
    margin-left: 0;
    margin-right: 33px;
}
.signin-col-form {
    max-width: 414px;
    margin-right: 0;
    margin-left: 33px;
}
.signin-logo {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
    height: 377px;
    width: 443px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}
@media screen and (max-width: 991px) {
    .signin-logo {
        margin-bottom: 43px;
        width: 100%;
        height: 233px;
    }
    .signin-block {
        width: 100%;
        margin: 0 auto;
        padding: 25px 9px 32px 9px;
    }
    .signin-col-form, .signin-col-logo {
        max-width: 100%;
        margin: 0;
    }
    .signin-wrapper {
        padding-top: 0;
    }
}
@media screen and (max-width: 767px) {
    .container {
        width: 100%;
    }

}
@media (max-width: 520px) {
    /* .signin-logo {
        width: 237px;
        height: 191px;
    } */
    /* .signin-logo img {
        width: 140px;
    } */
}
</style>
